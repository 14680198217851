import "./index.scss";
import React, { Component } from "react";

export default class Reviews extends Component {
  render() {
    return (
      <div className="col-12 mad-reviews-page">
        <div className="row mad-reviews-details d-none d-sm-flex">
          <div className="col-12">
            <h2>Reviews</h2>
          </div>
        </div>

        <div className="row mad-reviews-landing">
          <div className="col-12 col-lg-6 mad-image-container">
            <img src="/img/noodle.jpg" width="100%" alt="goldendoolde puppy" />
          </div>
          <div className="col-12 col-lg-6 mad-reviews-bio">
            <img
              className="mad-reviews-backdrop"
              src="/img/quote.png"
              height="30px"
              alt="quote decoration"
            />
            <h4>Noodle</h4>
            <p>
              My boyfriend and I are really glad in choosing Mauzy's Amazing
              Doodles!
              <br />
              <br />
              Our puppy is one of the sweetest, friendliest, and smartest
              dogs—Maybe I am biased but we get complimented on her constantly!
              We also get a lot of smiles from strangers whenever we go for a
              walk for how cute she is. Our dog ended up being 12lbs which is
              the perfect size for us as we live in San Francisco. <br />
              <br />
              Ruth (the breeder) is great at keeping in touch and giving advice
              as I am a first time dog owner. I definitely recommend Mauzy's
              Amazing Doodles as our puppy slept through the first night and was
              consistent at using her pee pads from the beginning! Thanks to
              Ruth's training :)
            </p>
          </div>
        </div>

        <div className="row mad-reviews-landing mad-dark">
          <div className="col-12 col-lg-6 mad-reviews-bio">
            <img
              className="mad-reviews-backdrop"
              src="/img/quote.png"
              height="30px"
              alt="quote decoration"
            />
            <h4>Daisy</h4>
            <p>
              Ruth worked with our family and got to know us before we adopted
              one of her pups. This ensured a great match and a smooth
              transition.
              <br />
              <br />
              Ruth's care and dedication go beyond the usual expectations. She
              establishes a gentle but unmistakable authority with each one of
              her pups, making it easy for them to slot into family life. When
              Daisy joined our family, we continued many of the commands Ruth
              had already established with her and within a few weeks, they were
              cemented. <br />
              <br />
              From the start, Ruth was available, just a text away, to answer
              any questions and offer the benefit of her experience. We still
              chat with her regularly and she is always so happy to receive
              update pictures of Daisy.
              <br />
              <br />
            </p>
          </div>

          <div className="col-12 col-lg-6 mad-image-container">
            <img src="/img/daisy.jpg" width="100%" alt="goldendoolde puppy" />
          </div>
        </div>

        <div className="row mad-reviews-landing">
          <div className="col-12 col-lg-6 mad-image-container">
            <img
              src="/img/annie_2.jpeg"
              width="100%"
              alt="Goldendoodle puppy"
            />
          </div>
          <div className="col-12 col-lg-6 mad-reviews-bio">
            <img
              className="mad-reviews-backdrop"
              src="/img/quote.png"
              height="30px"
              alt="quote decoration"
            />
            <h4>Annie</h4>
            <p>
              We welcomed our little (now 12lb.) Annie into our home in October
              2018 after a little coaching from Ruth and an easy flight to
              Seattle. <br />
              <br />
              Annie adapted quickly to the rhythms of our family, equally
              comfortable when she is contained in our urban home and running
              free at our rural cabin. She eagerly seeks out the chaotic company
              of children and other dogs, including her best friend Archie (our
              energetic 80lb. Flat-coated retriever), but she also loves to
              settle into our quiet times at home.
              <br />
              <br />
              Ruth has been absolutely incredible: a great communicator, she
              kept us informed throughout the adoption process, and she went out
              of her way to accommodate our many questions and strange requests.
              Best of all, Ruth remains sincerely interested in Annie and her
              progress. Her dogs love her dearly, and so do we!
            </p>
          </div>
        </div>

        <div className="row mad-reviews-landing mad-dark">
          <div className="col-12 col-lg-6 mad-reviews-bio">
            <img
              className="mad-reviews-backdrop"
              src="/img/quote.png"
              height="30px"
              alt="quote decoration"
            />
            <h4>Obi</h4>
            <p>
              We could not more highly recommend Ruth from Mauzy's Amazing
              Doodles as your breeder. Ruth raises her puppies in such a loving
              environment and you couldn't ask for a better start for your
              puppy.
              <br />
              <br /> We are first-time dog owners and Ruth was and is so very
              helpful with all of our questions. She's also so supportive in
              helping us care for our new puppy. We think our puppy is the best
              puppy in the world and I truly think that stems from the
              incredible beginning that he was blessed with under Ruth's care.
              Thank you Ruth!! We love our petite golden doodle!!
            </p>
          </div>
          <div className="col-12 col-lg-6 mad-image-container">
            <img src="/img/obi.JPG" width="100%" alt="Goldendoodle puppy" />
          </div>
        </div>

        <div className="row mad-reviews-landing">
          <div className="col-12 col-lg-6 mad-image-container">
            <img
              src="/img/hashbrown.jpg"
              width="100%"
              alt="Goldendoodle puppy"
            />
          </div>
          <div className="col-12 col-lg-6 mad-reviews-bio">
            <img
              className="mad-reviews-backdrop"
              src="/img/quote.png"
              height="30px"
              alt="quote decoration"
            />
            <h4>Hashbrown</h4>
            <p>
              Mauzy's Amazing Doodles has truly been a blessing. I had spent so
              much time interviewing and researching trying to find the right
              breeder to get my first dog, and then I came across Mauzy's
              Amazing Doodles. I remember visiting Ruth on several occasions to
              see the puppies in real life before making my decision, and every
              visit was enjoyable making me more confident in my decision to
              choose Mauzy's Amazing Doodles.
              <br />
              <br />
              Ruth is responsive and knowledgeable and genuinely cares about not
              just the pups, but also you as an owner. When Hashbrown became
              part of my family Ruth was always available to answer any
              questions which really helped me especially in the first couple
              months.
              <br />
              <br />
              Looking back at my experience, I can't imagine getting Hashbrown
              from anywhere else. He is everything I ever wanted in my first
              dog! At almost 2 years old now he is cuter than ever and has grown
              to be the perfect size (16 pounds) for my San Francisco apartment.
              Thanks so much Mauzy's Amazing Doodles!
            </p>
          </div>
        </div>

        <div className="row mad-reviews-landing mad-dark">
          <div className="col-12 col-lg-6 mad-reviews-bio">
            <img
              className="mad-reviews-backdrop"
              src="/img/quote.png"
              height="30px"
              alt="quote decoration"
            />
            <h4>Koyuki</h4>
            <p>
              I am very happy that I found Ruth (Mauzy's amazing doodles)
              through Facebook. I was looking for a breeder closer to me so that
              the puppy won't have to fly or travel on it's own. That's why I
              was so grateful when Ruth immediately replied to my Facebook
              message, and assured me that delivering the puppy is no problem.
              Through communicating with her and seeing all the cute videos and
              pictures of the puppies, I was able to truly see that the puppies
              were brought up in a very caring and loving environment. I felt
              that since we were welcoming a new member of our family, our puppy
              had to be a good fit for our family lifestyle. <br />
              <br />
              I have a 11 year old daughter who is an only child, so I needed a
              good companion for my daughter. I also work from home doing
              eyelashes, so the dog being hypoallergenic, and non aggressive was
              mandatory. Our Koyuki is very smart and has adjusted to my
              schedule perfectly!! All my clients are amazed at how well behaved
              my Koyuki is, and 2 of my clients were even inspired to get a
              goldendoodle of their own! They are very happy that they did, and
              we always have a fun visit every time they come in to get their
              lashes done!!
              <br />
              <br />
              My daughter is very attached to Koyuki, and surprisingly, my
              husband who was reluctant to the end is the most attached to
              Koyuki after all. He gets welcomed home and is in a better mood
              constantly than he was ever before. We are truly a happier family
              as we have been wanting a sibling for our daughter, and a good
              companion for all of us. I have so much more joy in my life that
              no words can accurately express it!! thanks to Ruth and Mauzy's
              Amazing doodles!
            </p>
          </div>
          <div className="col-12 col-lg-6 mad-image-container">
            <img src="/img/K.jpg" width="100%" alt="Goldendoodle puppy" />
          </div>
        </div>

        <div className="row mad-reviews-landing">
          <div className="col-12 col-lg-6 mad-image-container">
            <img src="/img/cali.jpg" width="100%" alt="Goldendoodle puppy" />
          </div>
          <div className="col-12 col-lg-6 mad-reviews-bio">
            <img
              className="mad-reviews-backdrop"
              src="/img/quote.png"
              height="30px"
              alt="quote decoration"
            />
            <h4>Cali</h4>
            <p>
              Ruth is a woman of integrity. She goes above and beyond as a
              breeder. She cares deeply for her puppies as well as the families
              who are adopting them. Ruth is knowledgeable, available, and
              friendly. She communicates with families throughout the entire
              process and sends lots of pictures. Ruth provides early
              neurological training which is critical for a puppy's cognitive
              development. She also begins litter box training and introduces
              crate training. These are key elements to set puppies up for
              success as they transition to their new home.
              <br />
              <br />
              We are so grateful for Ruth and all of her hard work to help make
              our puppy's journey home a smooth one. Choosing Ruth as our
              breeder was the BEST decision!
            </p>
          </div>
        </div>

        <div className="row mad-reviews-landing mad-dark">
          <div className="col-12 col-lg-6 mad-reviews-bio">
            <img
              className="mad-reviews-backdrop"
              src="/img/quote.png"
              height="30px"
              alt="quote decoration"
            />
            <h4>Willard</h4>
            <p>
              I got my sweet boy, Willard, from Mauzy's Amazing Doodles. Ruth
              was amazing. The whole process was very smooth. We were kept up to
              date even before conception. We were provided pictures from birth
              to the pick up day at least a few times a week and often every
              day. She provided training resources for us (Puppy Culture, a link
              to the free MadCap University as well as other tips) to prepare
              for our puppy and she continues to share helpful information with
              us to this day.
            </p>

            <p>
              Ruth does Puppy Culture with the puppies and even though it adds a
              tremendous amount of work for her as a breeder, the pay off is
              priceless. Our sweet Willard (@Willard.Safadoodle on Insta for
              pictures:-) was crate trained and potty trained to a litter box
              when he came home at 8 weeks. He has been the easiest puppy that I
              have ever had. He has the best temperament. We have not had any of
              the issues that I read about in my online doodle groups. He is
              literally perfect. He is also VERY handsome.
            </p>

            <p>
              We take him everywhere. He travels with us, stays in hotels, sits
              quietly in restaurants as we eat. Did I mention that he is only 5
              months old? He is so cute and well behaved that everyone adores
              him everywhere we go. I cannot say enough good things about
              Ruth/Mauzy's Amazing Doodles.
            </p>
          </div>
          <div className="col-12 col-lg-6 mad-image-container">
            <img src="/img/willard.jpg" width="100%" alt="Goldendoodle puppy" />
          </div>
        </div>

        <div className="row mad-reviews-landing">
          <div className="col-12 col-lg-6 mad-image-container">
            <img src="/img/sammy.png" width="100%" alt="Goldendoodle puppy" />
          </div>
          <div className="col-12 col-lg-6 mad-reviews-bio">
            <img
              className="mad-reviews-backdrop"
              src="/img/quote.png"
              height="30px"
              alt="quote decoration"
            />
            <h4>Sammy</h4>
            <p>
              I am so thankful I bought a goldendoodle from Ruth and Mauzy's
              Amazing Doodles. This was my first dog ever and I knew very little
              about how to take care of a dog. Ruth was great at texting me and
              answering questions. Also, goldendoodles are just the best dogs
              ever. He is such a good-natured dog that two of my neighbors have
              treats for him anytime we're outside. He loves every person he's
              ever met and every dog he's ever met. He did growl at a coyote one
              time which is good. He's great company; rarely barks, and sleeps a
              lot. I can leave him for five or six hours during the day and he
              doesn't seem to mind. I can also take him just about anywhere and
              he settles down and enjoys himself. I have no trouble finding
              babysitters for him when I need to go away because he's so
              enjoyable and playful. I was trying to think about what I didn't
              like about a goldendoodle, but there isn't anything that I don't
              like about a goldendoodle and especially my Sammy. One of the best
              decisions I made was to get a goldendoodle from Ruth. I just love
              my Sammy!
            </p>
          </div>
        </div>

        <div className="row mad-reviews-landing mad-dark">
          <div className="col-12 col-lg-6 mad-reviews-bio">
            <img
              className="mad-reviews-backdrop"
              src="/img/quote.png"
              height="30px"
              alt="quote decoration"
            />
            <h4>Bruno</h4>
            <p>
              It has been almost four months since we got our fur baby, Bruno
              home.
            </p>

            <p>
              Every person that I have shared Bruno's adoption experience with
              has been amazed by how wonderful a breeder Ruth is. We found her
              through a random Facebook search and we literally hit the jackpot.
              Right from when the puppies were born she regularly shared their
              pics and videos. We felt so involved in the whole process and it
              was as if we knew the personalities of the entire litter even
              before we went to pick our puppy.
            </p>

            <p>
              As first time pet parents, we needed a lot of handholding
              especially the first few weeks and Ruth was always there to guide
              us. She continues to support us even now. She uses great training
              resources. Bruno was very comfortable with his crate from the
              first night itself and already knew basic commands. Ruth truly
              loves all her puppies. She is emotionally bonded to them. Our
              puppy came home with his little blankie and the tug rope that Ruth
              made for him and his litter mates. That tug rope remains Bruno's
              favorite toy till date.
            </p>

            <p>
              As for Bruno, he is super cute and super smart. He has brought us
              so much joy. He enjoys long drives and is at his best behaviour at
              restaurants, resorts and wineries. Everywhere Bruno goes he
              constantly gets complimented on how cute and smart he is. This is
              becoming such a common occurrence that my hubby says we should
              have Ruth's business card on us at all times!! Ruth is a very
              passionate, loving and responsible breeder. We are so blessed to
              have found her. Thank you Ruth!!
            </p>
          </div>
          <div className="col-12 col-lg-6 mad-image-container">
            <img src="/img/bruno.jpg" width="100%" alt="Goldendoodle puppy" />
          </div>
        </div>

        <div className="row mad-reviews-landing">
          <div className="col-12 col-lg-6 mad-image-container">
            <img src="/img/bagel.jpg" width="100%" alt="Goldendoodle puppy" />
          </div>
          <div className="col-12 col-lg-6 mad-reviews-bio">
            <img
              className="mad-reviews-backdrop"
              src="/img/quote.png"
              height="30px"
              alt="quote decoration"
            />
            <h4>Bagel</h4>
            <p>
              We feel so lucky that we have found Ruth and her amazing doodles!
              I felt an instant connection with her when we first talked on the
              phone. As a first time dog owner, I had so many questions about
              the process. She talked to me in length about the steps and made
              me feel more confident about taking care of a dog.
            </p>

            <p>
              After the puppies were born, she sent us weekly video updates on
              all the puppies. She held each nugget to show us their cute faces.
              And many adorable moments of their daily activities. We were so
              excited to watch them everyday and knowing exactly how they grow
              week by week. Ruth went out her way tirelessly to condition the
              puppies. Playing thunder recordings, running the vacuum cleaner,
              brushing with electric tooth brush to get them use to grooming,
              putting each one of them in separate crates, potty training and
              teaching manding, just to name a few! At the same time, she also
              tried to prepare us the new puppy owners with free puppy training
              “puppy culture” video classes. This is a wonderful puppy behavior
              learning program. We have learned the most important concepts
              behind the importance of puppy training. By watching this program,
              it really helped me to be a better dog owner. While we were
              anxiously waiting for the puppies to grow up, we had a chance to
              visit the puppies twice! These wonderful interactions made us much
              at ease to take home a puppy.
            </p>

            <p>
              Ruth's support did not end at the time we picked up our precious
              puppy. She constantly checking in with all of us to see if we have
              any issues. And making videos to show us how to train puppies. I
              feel that I'm never alone to take on this huge task!
            </p>

            <p>
              Bagel is a very loving, talented, fun golden doodle. He loves to
              learn and play. We absolutely adore him! He was already potty
              trained when we got him. And slept through the night starting from
              the second night! He politely sits down to ask for anything and
              goes to his crate by himself when he is tired. No need to say
              more. We feel very very grateful to have found Ruth! She really
              cares about her puppies and their new owners. If there is a
              breeder award, I think she deserves a big trophy!!
            </p>
          </div>
        </div>

        <div className="row mad-reviews-landing mad-dark">
          <div className="col-12 col-lg-6 mad-reviews-bio">
            <img
              className="mad-reviews-backdrop"
              src="/img/quote.png"
              height="30px"
              alt="quote decoration"
            />
            <h4>Nala</h4>
            <p>
              My family and I are so grateful to have found Ruth and her
              doodles! From the first day I inquired about her puppies till this
              day she is always very responsive and informative. This was my
              family's first pup together and Ruth was so helpful and made the
              process so easy and calming for us. She answered the millions of
              questions I asked her and if she did not know the answer we found
              out together. From sending me constant pictures and videos of our
              sweet Nala while she was growing and getting ready to come to her
              forever home, to always staying in contact.
            </p>

            <p>
              I was so nervous to invest in a puppy but again Ruth was so
              helpful and answered every question I needed to feel safe and
              comfortable with to make a deposit. We love our Nala so much we
              received her healthy, happy and playful and till this day has
              remained the same! My family even wants a second doodle and they
              for sure want it to be from Ruth's doodles once again! Do not
              hesitate, she is great and has the cutest pups!
            </p>
          </div>
          <div className="col-12 col-lg-6 mad-image-container">
            <img src="/img/nala.jpg" width="100%" alt="Goldendoodle puppy" />
          </div>
        </div>

        <div className="row mad-reviews-landing">
          <div className="col-12 col-lg-6 mad-image-container">
            <img src="/img/abigail.jpg" width="100%" alt="Goldendoodle puppy" />
          </div>
          <div className="col-12 col-lg-6 mad-reviews-bio">
            <img
              className="mad-reviews-backdrop"
              src="/img/quote.png"
              height="30px"
              alt="quote decoration"
            />
            <h4>Abigail</h4>
            <p>
              Ruth from Mauzy's Amazing Doodles is incredible and highly
              recommend her. Ruth raises her puppies in a loving home
              environment and performs early training and testing, you couldn't
              ask for a better start for your puppy. I researched a long time
              for the right doodle breeder when I encountered Mauzy's Amazing
              Doodles. From the start, Ruth was available for a call or text to
              answer any questions and offered the option to come see the
              puppies... that made me more confident in my decision to get my
              puppy from Mauzy's Amazing Doodles.
            </p>

            <p>
              My little girl Abigail is the sweetest and smartest puppy. She is
              now just over 1 years old and weights 21 lbs. I cannot image my
              life without her.
            </p>
          </div>
        </div>

        <div className="row mad-reviews-landing mad-dark">
          <div className="col-12 col-lg-6 mad-reviews-bio">
            <img
              className="mad-reviews-backdrop"
              src="/img/quote.png"
              height="30px"
              alt="quote decoration"
            />
            <h4>Juniper</h4>
            <p>
              We welcomed Juniper into our home in September 2021. Our story is
              a little different as Juniper started off with another family and
              they were not able to keep her due to unforeseen circumstances.
              Juniper was almost 10 weeks old when she joined our family and
              Ruth was so helpful and made the transition seamless! Potty
              training was a breeze (one accident...that's it!) and she was
              crate trained and slept through the night very early on.
            </p>

            <p>
              As first time dog owners we had a lot of questions. Ruth answered
              texts quickly and was always willing to help and advise. She
              genuinely cares about the puppies and also the families that the
              puppies live with. She is extremely knowledgeable and a great
              communicator.
            </p>

            <p>
              At age one, Juniper weighs about 16lbs and is the perfect size.
              Her temperament is as sweet as can be and she is great with kids
              as well as other dogs. We often get stopped on the street for
              people to inquire which breeder we used. Ruth still asks for text
              updates and pictures every so often and we love that she keeps in
              touch. Thank you, Ruth!
            </p>
          </div>
          <div className="col-12 col-lg-6 mad-image-container">
            <img
              src="/img/juniper.jpeg"
              width="100%"
              alt="Goldendoodle puppy"
            />
          </div>
        </div>

        <div className="row mad-reviews-landing d-none d-sm-flex">
          <div className="col-12 col-lg-6 mad-image-container">
            <img src="/img/notes-3.jpg" width="50%" alt="Thank you note" />
            <img src="/img/notes-4.jpg" width="50%" alt="thank you note" />
          </div>

          <div className="col-12 col-lg-6 mad-image-container flex-column">
            <img src="/img/notes.jpg" width="100%" alt="thank you note" />
            <img src="/img/notes-2.jpg" width="100%" alt="thank you note" />
          </div>
        </div>
      </div>
    );
  }
}
